export class Filter {
  public readonly expression: string;
  public readonly color: string;
  public readonly excludesLines: boolean;
  public readonly isCaseSensitive: boolean;
  public readonly regex?: RegExp;

  private enabled: boolean = true;

  constructor(expression: string, color: string, excludesLines: boolean, isCaseSensitive: boolean, isRegex: boolean) {
    this.expression = expression;
    this.color = color;
    this.excludesLines = excludesLines;
    this.isCaseSensitive = isCaseSensitive;
    this.regex = isRegex ? new RegExp(expression, isCaseSensitive ? undefined : "i") : undefined;
  }

  readonly matchesLine = (line: string): boolean => {
    if (!this.regex) {
      return line.includes(this.expression) || (!this.isCaseSensitive && line.toLocaleLowerCase().includes(this.expression.toLowerCase()));
    } else {
      return line.match(this.regex) !== null;
    }
  }
}