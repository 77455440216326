function getPlatformModifier(): string {
  let modifierKeyPrefix = "ctrl+alt+"; // control key
  if (
    navigator.platform.indexOf("Mac") === 0 ||
    navigator.platform === "iPhone"
  ) {
    modifierKeyPrefix = "meta+alt+"; // command key
  }

  return modifierKeyPrefix;
}

export function getShortcut(key: string): string {
  const platformModifier = getPlatformModifier();
  return (platformModifier + key).toUpperCase();
}

export function getShortcutDisplay(hotkey: string): string {
  return hotkey.replace("META+ALT", "CMD+OPT");
}