import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import FilterMatch from "../data/FilterMatch";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterModal from "./FilterModal";
import { Filter } from "../data/Filter";
import { Line } from "../data/Types";
import { fixedWidth50 } from "./Styles";
import { useHotkeys } from "react-hotkeys-hook";
import { getShortcut, getShortcutDisplay } from "../data/Keyboard";

type FilterViewProps = {
  content: Line[];
  filterMatches: FilterMatch[];
  onFiltersChanged: (filterMatches: FilterMatch[]) => void;
}

export default function FilterView({
  content,
  filterMatches,
  onFiltersChanged
}: FilterViewProps) {
  const [hoveredFilter, setHoveredFilter] = useState<FilterMatch>();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const hotkey = getShortcut("N");
  useHotkeys([hotkey], () => setOpenFilterModal(true), { preventDefault: true });

  const handleFilterDelete = (filterRemoved: FilterMatch) => {
    onFiltersChanged(filterMatches.filter(f => f !== filterRemoved));
  }

  const handleFilterToggle = (filterToggled: FilterMatch) => {
    filterToggled.enabled = !filterToggled.enabled;
    onFiltersChanged([...filterMatches]);
  }

  const handleFilterAdd = (filterAdded: Filter) => {
    const newFilterMatch = new FilterMatch(filterAdded, content);
    onFiltersChanged([...filterMatches, newFilterMatch]);
  }

  return (
    <TableContainer component={Paper} style={{ maxWidth: '100%', maxHeight: '20%', overflowX: 'hidden', alignSelf: "flex-end" }}>
      <Table>
        <TableBody>
          {filterMatches.map(filterMatch => (
            <TableRow
              key={filterMatch.filter.expression}
              onMouseEnter={() => setHoveredFilter(filterMatch)}
              onMouseLeave={() => setHoveredFilter(undefined)}
            >
              <TableCell sx={fixedWidth50}>
                <Checkbox size="small" checked={filterMatch.enabled} onChange={() => handleFilterToggle(filterMatch)} />
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: filterMatch.filter.color }}
              >
                {"Filter \"" + filterMatch.filter.expression + `" matches ${filterMatch.matchCount} lines`}
              </TableCell>
              <TableCell sx={fixedWidth50}>
                {
                  hoveredFilter === filterMatch
                    ? <IconButton size="small" onClick={() => handleFilterDelete(filterMatch)}><DeleteIcon /></IconButton>
                    : <></>
                }
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Tooltip title={`[${getShortcutDisplay(hotkey)}] - Add new filter`}>
                <IconButton onClick={() => setOpenFilterModal(true)}><AddIcon /></IconButton>
              </Tooltip>
              <FilterModal
                key={filterMatches.reduce((prev, curr) => prev + curr.filter.expression, "")}
                open={openFilterModal}
                onCancel={() => setOpenFilterModal(false)}
                onSuccess={filter => {
                  handleFilterAdd(filter);
                  setOpenFilterModal(false);
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

