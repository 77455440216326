import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableTypeMap, useTheme } from "@mui/material";
import { Line, LineProvider } from "../data/Types";
import { fixedWidth50 } from "./Styles";
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type FileViewProps = {
  lineProvider: LineProvider;
}

function calculateTopMostItemIndex(content: Line[], countItemsRendered: number, selectedLine?: Line) {
  if (!selectedLine)
    return 0;

  let l = 0, r = content.length;
  let result = 0;
  while (l < r) {
    const m = Math.floor((l + r) / 2);
    if (content[m] === selectedLine) {
      result = m;
      break;
    } else if (content[m].index < selectedLine.index) {
      l = m + 1;
    } else {
      r = m;
    }

    result = l;
  }

  return Math.max(result - Math.floor(countItemsRendered / 2), 0);
}

const TableComponents = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props: DefaultComponentProps<TableTypeMap>) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
}

export default function FileView({ lineProvider }: FileViewProps) {
  const theme = useTheme();
  const tableRef = useRef<TableVirtuosoHandle>(null);
  const [countItemsRendered, setCountItemsRendered] = useState(0);

  const [selectedLine, setSelectedLine] = useState<Line>();
  const [scrolledContent, setScrolledContent] = useState(lineProvider);

  useEffect(() => {
    if (selectedLine && (lineProvider !== scrolledContent)) {
      const newScrollIndex = calculateTopMostItemIndex(lineProvider.lines, countItemsRendered, selectedLine);
      setTimeout(() => tableRef.current!.scrollToIndex(newScrollIndex), 0);
    }
    setScrolledContent(lineProvider);
  }, [selectedLine, lineProvider, scrolledContent, countItemsRendered]);

  const renderRow = useCallback((index: number) => {
    const line = lineProvider.lines[index];
    return (
      <TableRow
        key={line.index}
        onClick={() => setSelectedLine(selectedLine === line ? undefined : line)}
        sx={{ backgroundColor: selectedLine === line ? 'rgba(0, 123, 255, 0.1)' : 'inherit' }}
      >
        <TableCell align="right" sx={fixedWidth50}>{line.index}</TableCell>
        <TableCell sx={{ color: lineProvider.getLineColor(line, theme), width: "100%" }}>{line.content}</TableCell>
      </TableRow>
    )
  }, [lineProvider, selectedLine, theme]);

  return (
    <TableVirtuoso
      ref={tableRef}
      style={{ width: "100%", flexGrow: 1, overflowX: "hidden" }}
      overscan={5}
      data={lineProvider.lines}
      components={TableComponents}
      itemsRendered={(items) => items.length && setCountItemsRendered(items.length)}
      itemContent={renderRow}
      computeItemKey={(_, line) => line.index}
    />
  );
}