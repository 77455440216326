import { useState } from 'react';
import { Alert, Divider, Snackbar, ThemeProvider, Typography, createTheme } from '@mui/material';

import './App.css';
import FileInputButton from './components/FileInputButton';
import FileView from './components/FileView';
import FilterView from './components/FilterView';
import HiddenToggleButton from './components/HiddenToggleButton';
import FilterMatch from './data/FilterMatch';
import { FileLineProvider, FilteredLineProvider } from './data/LineProviders';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 19,
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '2px',
          paddingBottom: '2px',
          whiteSpace: "pre",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontFamily: "Hack, monospace",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Hack, monospace",
        },
      },
    },
  },
});

function App() {
  // State
  const [fileLineProvider, setFileLineProvider] = useState<FileLineProvider>();
  const [filteredLineProvider, setFilteredLineProvider] = useState<FilteredLineProvider>();
  const [showHiddenLines, setShowHiddenLines] = useState(false);

  // Handlers
  const handleFileLoad = (content: string) => {
    setFileLineProvider(new FileLineProvider(content));
  }

  const handleFiltersChanged = (filterMatches: FilterMatch[]) => {
    if (fileLineProvider && filterMatches.length > 0) {
      setFilteredLineProvider(new FilteredLineProvider(fileLineProvider.lines, filterMatches, showHiddenLines));
    } else {
      setFilteredLineProvider(undefined);
    }
  };

  const handleShowHidenLinesChanged = () => {
    const newShowHiddenLines = !showHiddenLines;
    setShowHiddenLines(newShowHiddenLines);
    if (filteredLineProvider) {
      setFilteredLineProvider(new FilteredLineProvider(fileLineProvider?.lines ?? [], filteredLineProvider.filterMatches, newShowHiddenLines));
    }
  }

  const lineProvider = filteredLineProvider ?? fileLineProvider;

  return (
    <div className="App">
      {
        lineProvider
          ? (
            <ThemeProvider theme={theme}>
              <FileView lineProvider={lineProvider} />
              <Divider />
              <FilterView
                content={fileLineProvider?.lines ?? []}
                filterMatches={filteredLineProvider?.filterMatches ?? []}
                onFiltersChanged={handleFiltersChanged}
              />
              <HiddenToggleButton showHidden={showHiddenLines} onClick={handleShowHidenLinesChanged} />
            </ThemeProvider>
          )
          : (
            <>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={true}
              >
                <Alert
                  variant="filled"
                  severity="success"
                  sx={{ width: '100%' }}
                >
                  Fast, on-device, text file analyzer. Open a text file and add filters to get started.
                </Alert>
              </Snackbar>
              <FileInputButton onFileLoaded={handleFileLoad} />
            </>
          )
      }
    </div>
  );
}

export default App;
