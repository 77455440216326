import Box from '@mui/material/Box';
import ArticleIcon from '@mui/icons-material/Article';

import { VoidCallback } from '../data/Types';
import { topRightTransparent } from './Styles';
import { IconButton, Tooltip } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { getShortcut, getShortcutDisplay } from '../data/Keyboard';

type HiddenToggleButtonProps = {
  showHidden: boolean;
  onClick: VoidCallback;
}

function HiddenToggleButton({ showHidden, onClick }: HiddenToggleButtonProps) {
  const hotkey = getShortcut("T");
  useHotkeys([hotkey], onClick, { preventDefault: true });

  return (
    <Box sx={topRightTransparent}>
      <Tooltip title={`[${getShortcutDisplay(hotkey)}] - Toggle view hidden lines`}>
        <IconButton onClick={onClick}><ArticleIcon fontSize="large" color={showHidden ? "primary" : "disabled"} /></IconButton>
      </Tooltip>
    </Box>
  );
}

export default HiddenToggleButton;