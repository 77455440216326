import { Theme } from "@mui/material";
import { Line, LineProvider } from "./Types";
import FilterMatch from "./FilterMatch";
import { BitVector } from "./BitVector";

export class FileLineProvider implements LineProvider {
  private readonly _lines: Line[];

  constructor(content: string) {
    const contentLines = content.split("\n");
    this._lines = contentLines.filter(line => line.length > 0).map((value, index) => ({ index: index + 1, content: value }));
  }

  public get lines() {
    return this._lines;
  }

  public readonly isLineVisible = () => true;
  public readonly getLineColor = (_: Line, theme: Theme): string => theme.palette.grey[900];
}

export class FilteredLineProvider implements LineProvider {
  private readonly _lines: Line[];
  private readonly _aggregatedMatches: BitVector;
  private readonly _filterMatches: FilterMatch[];

  constructor(contentLines: Line[], filterMatches: FilterMatch[], showAll: boolean) {
    // If there are no filters enabled or if the first filter is exclusive start with all matches.
    const firstFilter = filterMatches.find(filterMatch => filterMatch.enabled);
    const initialMatchValue = firstFilter?.filter.excludesLines ?? true;
    this._aggregatedMatches = new BitVector(contentLines.length, initialMatchValue);

    // Process active filters
    for (const filterMatch of filterMatches) {
      if (filterMatch.enabled) {
        if (filterMatch.filter.excludesLines) {
          // Exclusion filters remove from the current set
          this._aggregatedMatches.and(filterMatch.matches);
        } else {
          // Inclusive filters add to the current set
          this._aggregatedMatches.or(filterMatch.matches);
        }
      }
    }

    this._lines = showAll ? contentLines : contentLines.filter(line => this.isLineVisible(line));
    this._filterMatches = filterMatches;
  }

  public get lines() {
    return this._lines;
  }

  public get filterMatches() {
    return this._filterMatches;
  }

  public isLineVisible(line: Line) {
    return this._aggregatedMatches.get(line.index - 1);
  }

  public getLineColor(line: Line, theme: Theme) {
    if (!this.isLineVisible(line)) {
      return theme.palette.grey[500];
    } else {
      for (const filterMatch of this._filterMatches) {
        if (filterMatch.enabled && filterMatch.matchesLine(line) && !filterMatch.filter.excludesLines) {
          return filterMatch.filter.color;
        }
      }
    }

    return theme.palette.grey[900];
  }
}
