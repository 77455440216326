import { FormEvent, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SquareIcon from '@mui/icons-material/Square';

import { SketchPicker } from "react-color";

import { Filter } from '../data/Filter';
import { ValueCallback, VoidCallback } from '../data/Types';
import { centerStyle, centerStyleTransparent } from './Styles';

export type FilterModalProps = {
  open: boolean;
  defaultValue?: Filter;
  onSuccess: ValueCallback<Filter>;
  onCancel: VoidCallback;
}

function FilterModal(props: FilterModalProps) {
  const theme = useTheme();
  const [expression, setExpression] = useState(props.defaultValue?.expression ?? "");
  const [color, setColor] = useState(props.defaultValue?.color ?? theme.palette.grey[900]);
  const [excludesLines, setExcludesLines] = useState(false);
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);
  const [isRegex, setIsRegex] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const submitDisabled = !expression;

  const handleClose = () => {
    props.onCancel();
    resetForm();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSuccess(new Filter(expression, color, excludesLines, isCaseSensitive, isRegex));
    resetForm();
  }

  const resetForm = () => {
    setExpression("");
    setColor(theme.palette.grey[900]);
    setExcludesLines(false);
    setIsCaseSensitive(false);
    setIsRegex(false);
  }

  return (
    <>
      <Modal open={props.open} onClose={handleClose} aria-labelledby="color-modal-title">
        <form onSubmit={handleSubmit}>
          <FormControl sx={centerStyle}>
            <Box display="flex" justifyContent="space-between">
              <Typography id="color-modal-title" variant='h5'>Edit filter</Typography>
              <IconButton type="reset" onClick={() => props.onCancel()}><CloseIcon /></IconButton>
            </Box>

            <TextField
              fullWidth
              autoFocus
              label="Expression"
              value={expression}
              onChange={(e) => setExpression(e.target.value)}
            />

            <Box>
              <IconButton onClick={() => setShowColorPicker(true)}>
                <SquareIcon sx={{ color }} />
              </IconButton>

              <FormControlLabel
                control={<Checkbox checked={excludesLines} onChange={() => setExcludesLines(!excludesLines)} />}
                label="Exclude Lines"
              />

              <FormControlLabel
                control={<Checkbox checked={isCaseSensitive} onChange={() => setIsCaseSensitive(!isCaseSensitive)} />}
                label="Case Sensitive"
              />

              <FormControlLabel
                control={<Checkbox checked={isRegex} onChange={() => setIsRegex(!isRegex)} />}
                label="Regular Expression"
              />
            </Box>

            <Button variant="contained" type="submit" disabled={submitDisabled}>
              Confirm
            </Button>
          </FormControl>
        </form>
      </Modal >
      <Modal open={showColorPicker}>
        <Box sx={centerStyleTransparent}>
          <SketchPicker onChangeComplete={(color) => {
            setColor(color.hex);
            setShowColorPicker(false);
          }} />
        </Box>
      </Modal>
    </>
  );
}

export default FilterModal;