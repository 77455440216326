import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { centerStyleTransparent } from './Styles';

type FileInputButtonProps = {
  onFileLoaded: (content: string) => void;
}

function FileInputButton({ onFileLoaded }: FileInputButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startTime = performance.now();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (typeof (e.target?.result) === 'string') {
            const loadTime = performance.now();
            onFileLoaded(e.target.result);
            const parseTime = performance.now();
            console.log(`Perf ${loadTime - startTime}ms load`);
            console.log(`Perf ${parseTime - loadTime}ms parse`);
          }
        };
        reader.readAsText(file);
      }
    }
  };

  return (
    <Box sx={centerStyleTransparent}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Open File
      </Button>
    </Box>
  );
}

export default FileInputButton;