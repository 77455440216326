import { CSSProperties } from "react";

export const centerStyleTransparent: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px'
};

export const centerStyle: CSSProperties = {
  ...centerStyleTransparent,
  backgroundColor: 'white',
};

export const topRightTransparent: CSSProperties = {
  position: 'absolute',
  top: '1%',
  right: '1%',
  padding: '20px'
}

export const fixedWidth50: CSSProperties = {
  width: "50px",
}