const BUCKET_SIZE = 4;
const BIT_BUCKET_SIZE = 8 * BUCKET_SIZE;

export class BitVector {
  private readonly bitCount: number;
  private readonly buckets: number[];

  // Creates a new bit vector with the given bit count
  constructor(bitCount: number, value?: boolean) {
    this.bitCount = bitCount;
    this.buckets = new Array(Math.ceil(bitCount / BIT_BUCKET_SIZE));
    if (value !== undefined) {
      this.fill(value);
    }
  }

  // Get the bit size of the vector
  public get length() {
    return this.bitCount;
  }

  // Reset to a defined value
  public fill(value: boolean) {
    this.buckets.fill(value ? -1 : 0);
  }

  // Gets the bitvalue at the specified inded
  public get(index: number): boolean {
    this.checkIndex(index);

    const mask = 1 << (index % BIT_BUCKET_SIZE);
    const bucket = Math.floor(index / BIT_BUCKET_SIZE);

    return (this.buckets[bucket] & mask) === mask;
  }

  // Sets the bit value at the specified inded
  public set(index: number, value: boolean) {
    this.checkIndex(index);

    const mask = 1 << (index % BIT_BUCKET_SIZE);
    const bucket = Math.floor(index / BIT_BUCKET_SIZE);

    if (value) {
      this.buckets[bucket] |= mask;
    } else {
      this.buckets[bucket] &= ~mask;
    }
  }

  // ANDs the provided bit vector into the current one
  public and(rhs: BitVector): BitVector {
    this.checkLength(rhs.bitCount);

    for (let i = 0; i < this.buckets.length; i++) {
      this.buckets[i] &= rhs.buckets[i];
    }

    return this;
  }

  // ORs the provided bit vector into the current one
  public or(rhs: BitVector): BitVector {
    this.checkLength(rhs.bitCount);

    for (let i = 0; i < this.buckets.length; i++) {
      this.buckets[i] |= rhs.buckets[i];
    }

    return this;
  }

  // Reverses the values in the current bit vector
  public not(): BitVector {
    for (let i = 0; i < this.buckets.length; i++) {
      this.buckets[i] = ~this.buckets[i];
    }

    return this;
  }

  // Checks whether the provided index is a valid bit index
  private checkIndex(index: number) {
    if (index < 0 || this.bitCount <= index) {
      throw new Error(`Invalid index ${index}, expected in [0, ${this.bitCount}) range.`);
    }
  }

  // Check whether the length provided matches the current bit vector length
  private checkLength(length: number) {
    if (this.bitCount !== length) {
      throw new Error(`Invalid length ${length}, expected in ${this.bitCount}.`);
    }
  }
}