import { BitVector } from "./BitVector";
import { Filter } from "./Filter";
import { Line } from "./Types";



export function isLineVisible(line: Line, filterMatches: FilterMatch[]) {
  return (filterMatches.findIndex(match => match.enabled && match.matchesLine(line)) !== -1);
}

export default class FilterMatch {
  private readonly _filter: Filter;
  private readonly _matches: BitVector;
  private _matchCount = 0;

  public enabled: boolean;

  constructor(filter: Filter, content: Line[]) {
    this._filter = filter;
    this._matches = new BitVector(content.length, filter.excludesLines);
    this.enabled = true;

    this.updateMatches(content);
  }

  public get filter() {
    return this._filter;
  }

  public get matchCount() {
    return this._matchCount;
  }

  public get matches() {
    return this._matches;
  }

  public matchesLine(line: Line): boolean {
    return this._matches.get(line.index - 1);
  }

  private readonly updateMatches = (content: Line[]) => {
    this._matchCount = 0;
    for (const line of content) {
      if (this.filter.matchesLine(line.content)) {
        this._matches.set(line.index - 1, !this.filter.excludesLines);
        this._matchCount++;
      }
    }
  }
}

